.footer {
  background: #096262;
  padding: 40px;

  .logo {
    height: 54px;
    pointer-events: none;
    margin-bottom: 20px;
  }

  .copyright {
    font-size: 14px;
    color: white;
  }
}
