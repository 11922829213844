.header {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 80px 0px 40px;
  z-index: 1;

  .logo {
    height: 54px;
    pointer-events: none;
  }

}
