.plan-information {

  .title {
    font-family: Mulish;
    color: black;
    font-weight: 800;
    font-size: 32px;
  }

  .plan {
    font-family: Mulish;
    color: #7D7987;
    font-weight: 300;
    font-size: 21px;
    margin: 20px 0px 20px 0px;
  }

  .description {
    font-family: Mulish;
    color: #7D7987;
    font-weight: 300;
    font-size: 16px;
    margin: 20px 0px 20px 0px;
  }

  .button {
    background-color: '#13DEDE'
  }

}
