.plan-text {
  width: '100%';
  max-width: 500;
  margin: 60px 50px 0px 50px;
  text-align: left;

  .title {
    font-family: Mulish;
    color: black;
    font-weight: 800;
    font-size: 32px;
  }
}
