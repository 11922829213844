.home-content {

  display: flex;
  margin-top: -10px;
  margin-bottom: 120px;

  .doctors {
    height: 80vmin;
  }

}
