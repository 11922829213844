.button-group {
  display: flex;
  align-items: center;
  justify-items: flex-end;
  margin-top: 10px;

  .button {
    color: "red" !important;
  }
}
