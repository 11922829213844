.feedback-header {
  text-align: center;
  font-size: 32px;
  font-weight: 800;
  margin-top: 10%;
}

.feedback-information {
  text-align: center;
  margin-top: 10px;
  color: #7D7987;
}

.doctor-information {
  display: flex;
  justify-content: center;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.classification {
  display: flex;
  justify-content: center;
}
