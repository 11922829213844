.participant {
  .remote {
    .video {
      width: 100%;
      height: 100vmin;
      object-fit: cover;
    }
    .name {
      position: absolute;
      font-size: 40px;
      margin-left: 40px;
      margin-top: 100px;
    }
    .description {
      position: absolute;
      margin-top: 154px;
      margin-left: 40px;
      font-weight: 300;
    }
  }

  .local {
    display: flex;
    justify-content: flex-end;
    .video {
      width: 10%;
      border: solid;
      border-width: 2px;
      border-top-color: white;
      border-bottom-color: white;
      border-left-color: white;
      border-right-color: white;
      border-radius: 10px;
      height: 80px;
      object-fit: cover;
      margin-right: 3%;
    }

    .name {
      z-index: 2;
      position: absolute;
      right: 6%;
      top: 58%;
      font-size: 80%;
      color: white;
    }
  }
}
